import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { FaqAccordion } from '../faq-accordion'

interface Props {
  faqTitle: string
  faqs: any
  language: string
}

export const ContentFaq = ({ faqTitle, faqs, language }: Props) => {
  return (
    <section className="section-faq section-padding">
      <div className="container">
        <div className="row row-lg-50">
          <div className="col-lg-5 text-center text-md-left">
            <div className="box-holder">
              {faqTitle && faqTitle[language] && <h3 className="title text-center text-lg-left">{faqTitle[language]}</h3>}
            </div>
          </div>

          <div className="col-lg-7">
            <Accordion id="faq">
              {faqs.map((faq, index) => (
                <FaqAccordion key={index} index={index} {...faq} language={language} />
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  )
}
