import React, { useContext } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { AccordionContext } from 'react-bootstrap'
import PortableText from '../lib/portableText'

interface Props {
  index: string
  question: string
  _rawAnswer: string
  language: string
}

export const FaqAccordion = ({ index, question, _rawAnswer, language }: Props) => {
  function ContextAwareToggle() {
    const currentEventKey = useContext(AccordionContext)

    const isCurrentEventKey = currentEventKey === index + 1

    return (
      <Accordion.Toggle as={Card.Header} id={`heading-${index}`} eventKey={index + 1}>
        <div className={`question font-weight-bold d-flex align-items-center ${isCurrentEventKey ? '' : 'collapsed'}`}>
          {question[language]}
          <span className="icon" />
        </div>
      </Accordion.Toggle>
    )
  }

  return (
    <>
      {question[language] && _rawAnswer[language] && (
        <div className="card">
          <ContextAwareToggle />
          <Accordion.Collapse eventKey={index + 1}>
            <div className="card-body">
              <PortableText blocks={_rawAnswer[language]} />
            </div>
          </Accordion.Collapse>
        </div>
      )}
    </>
  )
}
