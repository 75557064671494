import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { getLocalizedLink, toExcerpt, trimWords } from '../../lib/helpers'

interface Props {
  heading: string
  language: string
}

export const ContentBlog = ({ heading, language }: Props) => {
  let data = useStaticQuery(graphql`
    {
      allSanityPost(limit: 4) {
        nodes {
          id
          _rawBody
          _rawSlug
          _rawTitle
          _rawTags
          image {
            asset {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  let blogPosts = data.allSanityPost.nodes
  const blog = {
    text: language === 'fi' ? 'Lue blogiamme' : 'Read our blog',
    link: language === 'fi' ? '/blog' : '/en/blog',
  }

  return (
    <section className="section-blog section-padding position-relative">
      <div className="container">
        {heading && (
          <div className="box-holder">
            <h2 className="h3 section-title text-center text-lg-left">{heading && heading[language]}</h2>
          </div>
        )}

        <div className="row row-lg-36 items-holder">
          {blogPosts[0] && (
            <div className="col-12 col-sm-6 col-lg-3 d-flex item">
              <div className="card-blog">
                <Link to={getLocalizedLink('blog/' +blogPosts[0]._rawSlug[language].current, language)}>
                  <div className="img-holder" style={{ backgroundImage: `url(${blogPosts[0].image?.asset?.localFile?.publicURL})` }} />

                  <div className="description">
                    <h5 className="title">{trimWords(blogPosts[0]._rawTitle[language], 4)}</h5>
                    <div className="text-content">{toExcerpt(blogPosts[0]._rawBody[language], 8)}</div>
                  </div>
                </Link>
              </div>
            </div>
          )}

          {blogPosts[1] && (
            <div className="col-12 col-sm-6 col-lg-3 d-flex item">
              <div className="card-blog">
                <Link to={getLocalizedLink('blog/' +blogPosts[1]._rawSlug[language].current, language)}>
                  <div className="img-holder" style={{ backgroundImage: `url(${blogPosts[1].image.asset.localFile.publicURL})` }} />

                  <div className="description">
                    <h5 className="title">{trimWords(blogPosts[1]._rawTitle[language], 4)}</h5>
                    <div className="text-content">{toExcerpt(blogPosts[1]._rawBody[language], 8)}</div>
                  </div>
                </Link>
              </div>
            </div>
          )}
          {blogPosts[2] && (
            <div className="col-12 col-sm-6 col-lg-3 d-flex item">
              <div className="card-blog">
                <Link to={getLocalizedLink('blog/' +blogPosts[2]._rawSlug[language].current, language)}>
                  <div className="img-holder" style={{ backgroundImage: `url(${blogPosts[2].image.asset.localFile.publicURL})` }} />

                  <div className="description">
                    <h5 className="title">{trimWords(blogPosts[2]._rawTitle[language], 4)}</h5>
                    <div className="text-content">{toExcerpt(blogPosts[2]._rawBody[language], 8)}</div>
                  </div>
                </Link>
              </div>
            </div>
          )}
          {blogPosts[3] && (
            <div className="col-12 col-sm-6 col-lg-3 d-flex item">
              <div className="card-blog">
                <Link to={getLocalizedLink('blog/' +blogPosts[3]._rawSlug[language].current, language)}>
                  <div className="img-holder" style={{ backgroundImage: `url(${blogPosts[3].image.asset.localFile.publicURL})` }} />

                  <div className="description">
                    <h5 className="title">{trimWords(blogPosts[3]._rawTitle[language], 4)}</h5>
                    <div className="text-content">{toExcerpt(blogPosts[3]._rawBody[language], 8)}</div>
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>

        <div className="d-flex justify-content-center">
          <Link to={blog.link} className="btn btn-lg btn-outline-primary">
            {blog.text}
          </Link>
        </div>
      </div>
    </section>
  )
}
