import React from 'react'
import { GatsbyImageSVGFallback } from './gatsby-image-svg-fallback'

interface Props {
  heading: string
  description: string
  image: any
  language: number
}

export const StepSingle = ({ heading, description, image, language }: Props) => {
  return (
    <div className="col-12 col-lg item d-flex flex-row flex-lg-column">
      {image && (
        <div className="icon">
          <GatsbyImageSVGFallback image={image} alt="icon" />
        </div>
      )}

      <div>
        {heading && heading[language] && <h5 className="item-title text-white">{heading[language]}</h5>}
        {description && description[language] && <div className="description">{description[language]}</div>}
      </div>
    </div>
  )
}
