import React from 'react'
import PortableText from '../lib/portableText'
import { GatsbyImageSVGFallback } from './gatsby-image-svg-fallback'

interface Props {
  title: string
  subtitle: string
  src: string
  description: string
}

export const ImageDescription = ({ title, subtitle, src, description }: Props) => {
  return (
    <div className="image-description">
      <div className="info-text position-relative">
        <div className="holder">
          {src && (
            <div className="info-icon bg-warning">
              <GatsbyImageSVGFallback image={src} alt="icon" />
            </div>
          )}

          <div>
            {title && (
              <h6 className="title">
                <b>{title}</b>
              </h6>
            )}
            {subtitle && <div className="subtitle">{subtitle}</div>}
          </div>
        </div>

        {description && (
          <div className="description">
            <PortableText blocks={description} />
          </div>
        )}
      </div>
    </div>
  )
}
