import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

interface Props {
  language: string
}

export const LandingPageFooter = ({ language }: Props) => {
  let data = useStaticQuery(graphql`
    {
      allSanitySiteSettings {
        nodes {
          _rawLeftLandingPageFooterBiline
          _rawLeftLandingPageFooterHeading
          _rawLeftLandingPageFooterLink1
          _rawLeftLandingPageFooterLink2
          _rawRightLandingPageFooterBiline
          _rawRightLandingPageFooterHeading
          _rawRightLandingPageFooterLink1
          _rawRightLandingPageFooterLink2
        }
      }
    }
  `)
  if (data?.allSanitySiteSettings?.nodes[0]) {
    let {
      _rawLeftLandingPageFooterHeading,
      _rawLeftLandingPageFooterBiline,
      _rawLeftLandingPageFooterLink1,
      _rawLeftLandingPageFooterLink2,
      _rawRightLandingPageFooterHeading,
      _rawRightLandingPageFooterBiline,
      _rawRightLandingPageFooterLink1,
      _rawRightLandingPageFooterLink2,
    } = data.allSanitySiteSettings.nodes[0]

    return (
      <section className="section-team position-relative">
        <div className="container">
          <div className="row row-lg-90">
            <div className="col-12 col-sm-6">
              <div className="member-team-card d-flex flex-column flex-lg-row align-items-center align-items-lg-start text-center text-lg-left">
                <div className="image-block">
                  <div className="img" />
                </div>
                <div className="info-block">
                  {_rawLeftLandingPageFooterHeading && <h4 className="member-item-title">{_rawLeftLandingPageFooterHeading[language]}</h4>}
                  {_rawLeftLandingPageFooterBiline && <div className="member-item-position">{_rawLeftLandingPageFooterBiline[language]}</div>}
                  <div className="member-item-description text-big"></div>
                  <div className="wrap-list text-big">
                    <ul className="list d-flex flex-wrap justify-content-center justify-content-lg-start">
                      {_rawLeftLandingPageFooterLink1 && (
                        <li>
                          <a href={_rawLeftLandingPageFooterLink1.linkHref[language]}>{_rawLeftLandingPageFooterLink1.linkText[language]}</a>
                        </li>
                      )}
                      {_rawLeftLandingPageFooterLink2 && (
                        <li>
                          <a href={_rawLeftLandingPageFooterLink2.linkHref[language]}>{_rawLeftLandingPageFooterLink2.linkText[language]}</a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="member-team-card d-flex flex-column flex-lg-row align-items-center align-items-lg-start text-center text-lg-left">
                <div className="image-block">
                  <div className="img" />
                </div>
                <div className="info-block">
                  {_rawRightLandingPageFooterHeading && <h4 className="member-item-title">{_rawRightLandingPageFooterHeading[language]}</h4>}
                  {_rawRightLandingPageFooterBiline && <div className="member-item-position">{_rawRightLandingPageFooterBiline[language]}</div>}
                  <div className="member-item-description text-big"></div>
                  <div className="wrap-list text-big">
                    <ul className="list d-flex flex-wrap justify-content-center justify-content-lg-start">
                      {_rawRightLandingPageFooterLink1 && (
                        <li>
                          <a href={_rawRightLandingPageFooterLink1.linkHref[language]}>{_rawRightLandingPageFooterLink1.linkText[language]}</a>
                        </li>
                      )}
                      {_rawRightLandingPageFooterLink2 && (
                        <li>
                          <a href={_rawRightLandingPageFooterLink2.linkHref[language]}>{_rawRightLandingPageFooterLink2.linkText[language]}</a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  return null
}
