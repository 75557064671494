import React from 'react'
import { Hero } from './hero'
import { ContentFaq } from './flexible-content-part/content-faq'
import { ContentBlog } from './flexible-content-part/content-blog'
import { ContentServices } from './flexible-content-part/content-services'
import { ContentSteps } from './flexible-content-part/content-steps'
import { ContentGetStarted } from './flexible-content-part/content-get-started'
import Reviews2 from "./reviews2";

interface Props {
  blocks: any
  language: string
  pageContent: any
}

export const MapToBlock = ({ blocks, language, pageContent }: Props) => {
  return (
    <>
      {blocks.map((contentBlock, key) => {
        switch (contentBlock._type) {
          case 'hero':
            return <Hero key={key} {...contentBlock} language={language} />
          case 'faq':
            return <ContentFaq key={key} {...contentBlock} language={language} />
          case 'getStarted':
            return <ContentGetStarted key={key} {...contentBlock} language={language} />
          case 'recentBlogs':
            return <ContentBlog key={key} {...contentBlock} language={language} />
          case 'services':
            return <ContentServices key={key} {...contentBlock} language={language} />
          case 'steps':
            return <ContentSteps key={key} {...contentBlock} language={language} />
          case 'reviews':
            return <Reviews2 pageContent={pageContent} key={key} {...contentBlock} language={language} />
        }
      })}
    </>
  )
}
