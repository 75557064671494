import React from 'react'
import { ServicesSingle } from '../services-single'

interface Props {
  services: any
  servicesTitle: string
  language: string
}

export const ContentServices = ({ services, servicesTitle, language }: Props) => {
  return (
    <section className="section-services position-relative">
      <div className="container">
        <div className="box-holder">
          {servicesTitle && servicesTitle[language] && <h2 className="h3 section-title text-center text-lg-left">{servicesTitle[language]}</h2>}

          {services && (
            <div className="row items">
              {services.map((service, key) => (
                <ServicesSingle key={key} {...service} language={language} />
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
