import React from 'react'
import {ImageDescription} from './image-description'
import PortableText from '../lib/portableText'
import {Link} from 'gatsby'
import {GatsbyImageSVGFallback} from './gatsby-image-svg-fallback'

interface Props {
  heading: string
  heroImage: any
  _rawBody: string
  buttonText: string
  link: string
  layeredTitle: string
  layeredImage: any
  layeredSubtitle: string
  _rawLayeredDescription: string
  language: number
}

export const Hero = ({
                       heading,
                       heroImage,
                       _rawBody,
                       buttonText,
                       link,
                       layeredTitle,
                       layeredImage,
                       layeredSubtitle,
                       _rawLayeredDescription,
                       language,
                     }: Props) => {
  const altText = heroImage?.altText[language] || ''
  return (
    <section className="section-hero section-padding">
      <div className="container">
        <div className="row flex-md-row-reverse row-md-100 align-items-center">
          <div className="col-md-7">
            <div className="box-text text-center text-md-left">
              <div className="row">
                <div className="col-md-10">
                  {heading && heading[language] && <h1 className="title text-white">{heading[language]}</h1>}

                  {_rawBody && _rawBody[language] && (
                    <div className="row">
                      <div className="col-lg-11">
                        <div className="description">
                          <PortableText blocks={_rawBody[language]}/>
                        </div>
                      </div>
                    </div>
                  )}

                  {buttonText && buttonText[language] && link && link[language] && (
                    <a className="btn btn-lg btn-primary" href={link[language]}>{buttonText[language]}</a>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            {heroImage && (
              <div className="box-img img-border">
                <div className="img-wrapper">{heroImage && <GatsbyImageSVGFallback image={heroImage} alt={altText}/>}</div>
                <ImageDescription
                  src={layeredImage}
                  title={layeredTitle && layeredTitle[language]}
                  subtitle={layeredSubtitle && layeredSubtitle[language]}
                  description={_rawLayeredDescription && _rawLayeredDescription[language]}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
