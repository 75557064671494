import React from 'react'
import { Link } from 'gatsby'

interface Props {
  heading: string
  description: string
  buttonText: string
  link: string
  language: string
}

export const ContentGetStarted = ({ heading, description, buttonText, link, language }: Props) => {
  return (
    <section className="section-get-started section-padding">
      <div className="container">
        <div className="box-holder align-items-center row">
          <div className="col-md-6 text-center text-md-left">
            {heading && heading[language] && <h2 className="title">{heading[language]}</h2>}

            {description && description[language] && <div className="description text-big">{description[language]}</div>}
          </div>
          {buttonText && buttonText[language] && link && link[language] && (
            <div className="col-md-6 text-center text-md-right">
              <a className="btn btn-lg btn-primary" href={link[language]}>
                {buttonText[language]}
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
