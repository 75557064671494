import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import { MapToBlock } from '../components/map-to-block'
import { Seo } from '../components/seo'
import { LandingPageFooter } from '../components/landing-page-footer'

export const query = graphql`
  query LandingPageTemplateQuery($id: String!) {
    post: sanityLandingPage(id: { eq: $id }) {
      id
      title {
        en
        et
        fi
      }
      description {
        en
        et
        fi
      }
      slug {
        en {
          current
        }
        fi {
          current
        }
        et {
          current
        }
      }
      content {
        ... on SanityHero {
          _type
          heroImage {
            altText {
              en
              fi
              et
            }
            asset {
              localFile {
                id
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          layeredImage {
            asset {
              localFile {
                id
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          _rawBody
          layeredSubtitle {
            en
            fi
            et
          }
          layeredTitle {
            en
            fi
            et
          }
          _rawLayeredDescription
          buttonText {
            en
            et
            fi
          }
          link {
            en
            et
            fi
          }
          heading {
            en
            et
            fi
          }
        }
        ... on SanityRecentBlogs {
          _type
          heading {
            en
            et
            fi
          }
        }
        ... on SanitySteps {
          _type
          stepsTitle {
            en
            et
            fi
          }
          steps {
            image {
              asset {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            heading {
              en
              et
              fi
            }
            description {
              en
              et
              fi
            }
          }
        }
        ... on SanityServices {
          _type
          services {
            _rawBody
            layeredImage {
              asset {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            _rawLayeredDescription
            layeredSubtitle {
              en
              et
              fi
            }
            layeredTitle {
              en
              et
              fi
            }
            heading {
              en
              et
              fi
            }
            image {
              altText {
                en
                fi
                et
              }
              asset {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          servicesTitle {
            en
            et
            fi
          }
        }
        ... on SanityGetStarted {
          _type
          buttonText {
            en
            et
            fi
          }
          description {
            en
            et
            fi
          }
          heading {
            en
            fi
            et
          }
          link {
            et
            en
            fi
          }
        }
        ... on SanityFaq {
          _type
          faqTitle {
            en
            et
            fi
          }
          faqs {
            _rawAnswer
            question {
              en
              et
              fi
            }
          }
        }
        ... on SanityReviews {
          _key
          basedOn {
            en
            fi
          }
          customerReviews {
            fi
            en
          }
          ifYouHave {
            fi
            en
          }
          recent {
            fi
            en
          }
          reviews {
            fi
            en
          }
          share {
            fi
            en
          }
          writeReview {
            en
            fi
          }
          _type
        }
      }
    }
  }
`

const LandingPageTemplate = (props) => {
  const {
    data: { post },
    errors,
    location,
  } = props
  let lang = props.pageContext.language
  const metas = [
    {
      property: 'og:type',
      content: 'website',
    },
  ]
  return (
    <>
      {errors && <GraphQLErrorList errors={errors} />}
      <>
        <Seo title={post.title} language={lang} location={location} description={post.description} metas={metas} />
        <MapToBlock blocks={post.content} pageContent={props.pageContext} language={lang} />
        <LandingPageFooter language={lang} />
      </>
    </>
  )
}

export default LandingPageTemplate
