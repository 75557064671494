import React from 'react'
import { StepSingle } from '../step-single'

interface Props {
  steps: any
  stepsTitle: string
  language: string
}

export const ContentSteps = ({ steps, stepsTitle, language }: Props) => {
  return (
    <section className="section-steps section-padding">
      <div className="container">
        <div className="box-holder">
          {stepsTitle && stepsTitle[language] && <h3 className="section-title text-center text-lg-left text-white">{stepsTitle[language]}</h3>}

          {steps && (
            <div className="row">
              {steps.map((step, key) => (
                <StepSingle key={key} {...step} language={language} />
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
