import React from 'react'
import { ImageDescription } from './image-description'
import PortableText from '../lib/portableText'
import { GatsbyImageSVGFallback } from './gatsby-image-svg-fallback'

interface Props {
  heading: string
  image: any
  layeredImage: any
  layeredTitle: string
  layeredSubtitle: string
  _rawLayeredDescription: string
  language: number
  _rawBody: any
}

export const ServicesSingle = ({
  heading,
  _rawBody,
  image,
  layeredImage,
  layeredTitle,
  layeredSubtitle,
  _rawLayeredDescription,
  language,
}: Props) => {
  const altText = image?.altText[language] || ''
  return (
    <div className="col-12 item">
      <div className="row row-lg-60 row-xl-60 inner-holder">
        <div className="col-md">
          <div className="info text-center text-md-left">
            {heading && heading[language] && (
              <div className="row">
                <div className="col-lg-10">
                  <h3 className="h2 item-title">{heading[language]}</h3>
                </div>
              </div>
            )}
            {_rawBody && _rawBody[language] && (
              <div className="description">
                <PortableText blocks={_rawBody[language]} />
              </div>
            )}
          </div>
        </div>

        <div className="col-md">
          {image && (
            <div className="img img-border position-relative">
              <div className="img-wrapper">
                <GatsbyImageSVGFallback image={image} alt={altText} />
              </div>
              <ImageDescription
                src={layeredImage}
                title={layeredTitle && layeredTitle[language]}
                subtitle={layeredSubtitle && layeredSubtitle[language]}
                description={_rawLayeredDescription && _rawLayeredDescription[language]}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
