import React from 'react'
/* This example requires Tailwind CSS v2.0+ */
/* This example requires Tailwind CSS v2.0+ */
import {StarIcon} from '@heroicons/react/solid'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Reviews2(props) {
  const {language, customerReviews, basedOn, share, ifYouHave, writeReview, recent} = props
  const googleReview = props.pageContent?.googleReviews || {}
  const reviews = {
    average: googleReview.rating,
    totalCount: googleReview.user_ratings_total,
    featured: googleReview.reviews?.map((review, index) => {
      return {
        id: index,
        rating: review.rating,
        content: `<p>${review.text}</p>`,
        avatarSrc: review.profile_photo_url,
        author: review.author_name
      }
    })
  }
  const translate = {
    customerReviews: customerReviews[language],
    basedOn: basedOn[language],
    reviews: props.reviews[language],
    share: share[language],
    ifYouHave: ifYouHave[language],
    writeReview: writeReview[language],
    recent: recent[language]
  }
  return (
    <div className="section-review">
      <div className="container-review">
        <div className="bg-white">
          <div
            className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:py-32 lg:px-8 lg:grid lg:grid-cols-12 lg:gap-x-8">
            <div className="lg:col-span-4">
              <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">{translate.customerReviews}</h2>

              <div className="mt-3 flex items-center">
                <div>
                  <div className="flex items-center">
                    {[0, 1, 2, 3, 4].map((rating) => (
                      <StarIcon
                        key={rating}
                        className={classNames(
                          reviews.average > rating ? 'text-yellow-400' : 'text-gray-300',
                          'flex-shrink-0 h-5 w-5'
                        )}
                        aria-hidden="true"
                      />
                    ))}
                  </div>
                  <p className="sr-only">{reviews.average} out of 5 stars</p>
                </div>
                <p
                  className="ml-2 text-sm text-gray-900">{reviews.average}/5.  {translate.basedOn} {reviews.totalCount} {translate.reviews}</p>
              </div>

              <div className="mt-6">
                <h3 className="sr-only">Review data</h3>
              </div>

              <div className="mt-10">
                <h3 className="text-lg font-medium text-gray-900">{translate.share}</h3>
                <p className="mt-1 text-sm text-gray-600">
                  {translate.ifYouHave}
                </p>

                <a
                  href="https://g.page/r/CZQEJ9a9q1OIEAg/review"
                  className="mt-6 inline-flex w-full bg-white border border-gray-300 rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-gray-50 sm:w-auto lg:w-full"
                >
                  {translate.writeReview}
                </a>
              </div>
            </div>

            <div className="mt-16 lg:mt-0 lg:col-start-6 lg:col-span-7">
              <h3 className="sr-only">Recent reviews</h3>

              <div className="flow-root">
                <div className="-my-12 divide-y divide-gray-200">
                  {reviews.featured.map((review) => (
                    <div key={review.id} className="py-12">
                      <div className="flex items-center">
                        <img src={review.avatarSrc} alt={`${review.author}.`} className="h-12 w-12 rounded-full"
                             referrerPolicy="no-referrer"/>
                        <div className="ml-4">
                          <h4 className="text-sm font-bold text-gray-900">{review.author}</h4>
                          <div className="mt-1 flex items-center">
                            {[0, 1, 2, 3, 4].map((rating) => (
                              <StarIcon
                                key={rating}
                                className={classNames(
                                  review.rating > rating ? 'text-yellow-400' : 'text-gray-300',
                                  'h-5 w-5 flex-shrink-0'
                                )}
                                aria-hidden="true"
                              />
                            ))}
                          </div>
                          <p className="sr-only">{review.rating} out of 5 stars</p>
                        </div>
                      </div>

                      <div
                        className="mt-4 space-y-6 text-base italic text-gray-600"
                        dangerouslySetInnerHTML={{__html: review.content}}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

